import './style.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Models
 */
const gltfLoader = new GLTFLoader()

const updateMaterial = () => {
    scene.traverse((child) => {
        if(child instanceof THREE.Mesh) {
            const newMaterial = new THREE.MeshStandardMaterial();
            const outlineMaterial = new THREE.MeshBasicMaterial({color:'#242b5a'});

            if(child.name === 'Cube001_2'){
                child.material = outlineMaterial;
            } else {
                child.material = newMaterial;
            }
        }
    });
}

const material = new THREE.MeshToonMaterial()
gltfLoader.load(
    '/models/cacamba-outline3.glb',
    (gltf) => {
        gltf.scene.scale.set(0.6, 0.6, 0.6)
        gltf.scene.position.set(10, 2, 0)
        gltf.scene.rotation.set(0.5, 5.15, 0)

        if(window.innerWidth > 1920){
            gltf.scene.scale.set(0.4, 0.4, 0.4)
            gltf.scene.position.set(8.5, 3, 0)
        }
        if(window.innerWidth < 1080 && window.innerWidth > 550){
            gltf.scene.scale.set(0.44, 0.44, 0.44)
            gltf.scene.position.set(8.5, 2.5, 0)
        }
        if(window.innerWidth < 550){
            gltf.scene.scale.set(0.3, 0.3, 0.3)
            gltf.scene.position.set(5, 2.5, 0)
            gltf.scene.rotation.set(0.5, 5.5, 0)
        }

        scene.add(gltf.scene)

        updateMaterial()
    }
)

const onResize = function(){
    scene.traverse((child) => {
        if(child instanceof THREE.Mesh && child.name === 'Cube001_2') {
            console.log(child)

            child.scale.set(0.6, 0.6, 0.6)
            child.position.set(10, 2, 0)
            //child.rotation.set(0.5, 5.15, 0)

            if(window.innerWidth > 1920){
                child.scale.set(0.4, 0.4, 0.4)
                child.position.set(8.5, 3, 0)
            }
            if(window.innerWidth < 1080 && window.innerWidth > 550){
                child.scale.set(0.44, 0.44, 0.44)
                child.position.set(8.5, 2.5, 0)
            }
            if(window.innerWidth < 550){
                child.scale.set(0.3, 0.3, 0.3)
                child.position.set(5, 2.5, 0)
                //child.rotation.set(0.5, 5.5, 0)
            }
        }
    });
}

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xc6c8d6, 0.7)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
directionalLight.castShadow = false
directionalLight.position.set(0, 6, 5)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update animation variables
    windowX = window.innerWidth / 2;
    windowY = window.innerHeight / 2;

    //onResize();
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100)
camera.position.set(5, 3, 10)
scene.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
let mouseX = 0
let mouseY = 0
let targetX = 0
let targetY = 0

let windowX = window.innerWidth / 2;
let windowY = window.innerHeight / 2;

document.addEventListener('mousemove', function(event){
    mouseX = (event.clientX - windowX)
    mouseY = (event.clientY - windowY)
})

const clock = new THREE.Clock()
let previousTime = 0
const tick = () => {
    targetX = mouseX * .0005
    targetY = mouseY * .0005

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update objects
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh){
            child.rotation.y += .065 * (targetX - child.rotation.y)
            child.rotation.x += .065 * (targetY - child.rotation.x)
            //child.rotation.z += .065 * (targetX / 2 - child.rotation.z)
        }
    });

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}
tick()